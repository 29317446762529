import React, { lazy, Suspense, useMemo, useState } from 'react';
import {
Block,
BlockBetween,
BlockHead,
BlockHeadContent,
BlockTitle,
DataTable,
DataTableBody,
DataTableItem,
DataTableRow,
Icon,
PaginationComponent,
PreviewAltCard,
TooltipComponent,
UserAvatar
} from '../Component';
import { SHOW_ACTIVITIES_OPTIONS } from './constants';
import {
Badge,
Button,
Col,
DropdownItem,
DropdownMenu,
DropdownToggle,
Modal,
ModalBody,
ModalHeader,
Row,
Tooltip,
UncontrolledDropdown
} from 'reactstrap';
import { getActivityBadgeStyles } from './utils';
import CardInner from '../cards/CardInner';
import CardTitleGroup from '../cards/CardTitleGroup';
import CardTools from '../cards/CardTools';
import ActivitySkeletonLoader from './components/ActivitySkeletonLoader';
import { format } from 'date-fns';
import { ActivityStatus, ActivityTypes, getPublicDocumentUrl, LeadStatus, ScreenBreakPoints } from '../../utils/envConfig';
import '../../api/global-types/types';
import ActivityCreationForm from './forms/ActivityCreationForm';
import { ActivityProvider } from './context/ActivityProvider';
import { createActivity } from '../../api/activities/createActivity';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { showToast } from '../../utils/toast/toast';
import useMutationQuery from '../../hooks/useMutationQuery';
import LoaderModal from '../modals/LoaderModal';
import useScreenSize from '../../hooks/useScreenSize';
import { Link } from 'react-router-dom';
import TooltipCard from '../tooltip/TooltipCard';
import PropertyListHoverCard from './components/PropertyListHoverCard';
import { findUpper } from '../../utils/Utils';

const ActivityEditModal = lazy(() => import('./modals/ActivityEditModal'));
const ActivityCompletionModal = lazy(() => import('./modals/ActivityCompletionModal'));

/**
 * @typedef {Object} ActivitiesState
 * @property {Array<UserActivity>} activities - The array of activities data.
 * @property {boolean} isLoading - Whether the activities are currently being loaded.
 * @property {Error} error - Any error that occurred during data fetching.
 */

/**
 * A table component for displaying user activities.
 *
 * @param {Object} props - The props for the component.
 * @param {ActivitiesState} props.activitiesState - The activities state.
 * @param {User} props.user - The user associated with the activities
 * @param {QueryState} props.queryParams - The query params state
 * @param {import("react").Dispatch<import("react").SetStateAction<QueryState>>} props.setQueryParams - The React setter function for the query params state
 * @returns {JSX.Element} - The rendered table component.
 */
const ActivitiesTableView = ({ activitiesState, user, queryParams, setQueryParams }) => {
  const axios = useAxiosPrivate();
  const { width: screenWidth } = useScreenSize();
  const [searchText, setSearchText] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const toggleSearch = () => setIsSearchOpen(!isSearchOpen);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const [adminAddTaskModal, setAdminAddTaskModal] = useState(false);
  const toggleAdminAddTaskModal = () => {
    setAdminAddTaskModal(!adminAddTaskModal);
  };

  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false);
  const toggleCompleteModal = () => {
    setIsCompleteModalOpen(!isCompleteModalOpen);
  };
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const toggleViewModal = () => {
    setIsViewModalOpen(!isViewModalOpen);
  };
  const [activityToComplete, setActivityToComplete] = useState(null);
  const [activityToEdit, setActivityToEdit] = useState(null);
  const [activityToView, setActivityToView] = useState(null);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  const { userStatus } = useMemo(
    () => ({
      userStatus: user?.lead_status ?? ''
    }),
    [user]
  );
  const { hasPendingOrOverdue } = useMemo(
    () => ({
      hasPendingOrOverdue: activitiesState?.activities?.some((activity) =>
        [ActivityStatus.Overdue, ActivityStatus.Pending].includes(activity?.status ?? '')
      )
    }),
    [activitiesState?.activities]
  );

  const activityCreate = useMutationQuery((payload) => createActivity(axios, payload), {
    onError: (error) => {
      showToast('Failed to create task.', 'error');
      console.log(error);
    }
  });

  if (activitiesState.error) {
    return <div>Error: {activitiesState.error.message}</div>;
  }

  // Function to detect and replace URLs with anchor tags
  const renderWithLinks = (text) => {
    if (!text) return '-';
    const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;

    const formattedText = text.replace(urlPattern, (url) => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });

    return <span dangerouslySetInnerHTML={{ __html: formattedText }} />;
  };
  console.log(activitiesState?.activities);


  const shouldShowSystemInfo = (activity) => {
  const hiddenStatus = [
    ActivityTypes.Call,
    ActivityTypes.Appointment,
    ActivityTypes.WhatsApp,
    ActivityTypes.Viewing,
    ActivityTypes.SMSMessage,
  ];

  const isAgentTypeActivity = hiddenStatus.includes(activity.type);

  if (!isAgentTypeActivity) {
    // For non-agent activities, check if the description is empty or very short
    const description = (activity?.description ?? "").toString().trim();
    return description.length > 1; // Show system info only if description is non-empty
  } else {
    // For agent activities, check if the notes are empty or very short
    const notes = (activity?.notes ?? "").toString().trim();
    return notes.length <= 1; // Show system info if notes are empty
  }
};

  return (
    <React.Fragment>
      <BlockHead id={'activities'}>
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Activities</BlockTitle>
          </BlockHeadContent>
          <BlockHeadContent>
            {userStatus !== LeadStatus.Disqualified ? (
              <>
                <Button
                  className="bg-primary text-white"
                  onClick={(ev) => {
                    ev.preventDefault();
                    if (hasPendingOrOverdue) {
                      return;
                    }
                    toggleAdminAddTaskModal();
                  }}
                  disabled={hasPendingOrOverdue}
                  id="addTaskButton"
                >
                  <Icon name={'plus'} className={'me-1'} />
                  Add Task
                </Button>
                <Tooltip isOpen={tooltipOpen} target="addTaskButton" toggle={() => setTooltipOpen((prev) => !prev)}>
                  {hasPendingOrOverdue ? 'Please complete pending tasks' : 'Add a new task for this lead'}
                </Tooltip>
              </>
            ) : null}
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <Block>
        <DataTable className={'card-stretch mt-2 mx-1 mx-sm-0'}>
          <CardInner className="position-relative card-tools-toggle py-4">
            <CardTitleGroup>
              <CardTools>
                <div className="form-inline flex-nowrap gx-3"></div>
              </CardTools>

              <CardTools className="me-n1">
                <ul className="btn-toolbar gx-1">
                  <li>
                    <a
                      href="#search"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleSearch();
                      }}
                      className="btn btn-icon search-toggle toggle-search"
                    >
                      <Icon name="search"></Icon>
                    </a>
                  </li>

                  <li className="btn-toolbar-sep"></li>

                  <li>
                    <div className="toggle-wrap">
                      <div className={`toggle-content`}>
                        <ul className="btn-toolbar gx-1">
                          <li>
                            <UncontrolledDropdown>
                              <DropdownToggle tag="span" className="btn btn-trigger btn-icon dropdown-toggle">
                                <Icon name="setting"></Icon>
                              </DropdownToggle>
                              <DropdownMenu end className="dropdown-menu-xs">
                                <ul className="link-check">
                                  <li>
                                    <span>Show</span>
                                  </li>
                                  {SHOW_ACTIVITIES_OPTIONS.map((option, idx) => (
                                    <li
                                      className={queryParams?.pageSize === option ? 'active' : ''}
                                      key={`act-show-option-${idx}`}
                                    >
                                      <DropdownItem
                                        tag="span"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setQueryParams((prev) => ({
                                            ...prev,
                                            pageSize: option,
                                            page: 1
                                          }));
                                        }}
                                      >
                                        {option}
                                      </DropdownItem>
                                    </li>
                                  ))}
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </CardTools>
            </CardTitleGroup>
            <div className={`card-search search-wrap ${isSearchOpen && 'active'}`}>
              <div className="card-body">
                <div className="search-content">
                  <Button
                    className="search-back btn-icon toggle-search active bg-transparent text-secondary"
                    onClick={() => {
                      setQueryParams((prev) => ({
                        ...prev,
                        type: ''
                      }));
                      setSearchText('');
                      toggleSearch();
                    }}
                  >
                    <Icon name="arrow-left"></Icon>
                  </Button>
                  <input
                    type="text"
                    className="border-transparent form-focus-none form-control ps-5  bg-transparent text-secondary"
                    placeholder="Search Activity by Type"
                    value={searchText}
                    onChange={(e) => {
                      setQueryParams((prev) => ({
                        ...prev,
                        type: e.target.value,
                        page: 1
                      }));
                      setSearchText(e.target.value);
                    }}
                  />
                  <Button className="search-submit btn-icon  bg-transparent text-secondary border-0">
                    <Icon name="search"></Icon>
                  </Button>
                </div>
              </div>
            </div>
          </CardInner>

          <DataTableBody>
            {/*! LEAVE IN CASE WE WANT THE HEADERS BACK */}
            {/* <DataTableHead className="nk-tb-item">
              {HEADER_FIELDS.map((header, idx) =>
                isRendered(header) ? (
                  <DataTableRow size={header?.breakpoint} className={''} key={`activity-header-${idx}`}>
                    <span className="sub-text">{header?.label}</span>
                  </DataTableRow>
                ) : null
              )}
            </DataTableHead> */}

            {activitiesState?.isLoading ? (
              <ActivitySkeletonLoader />
            ) : (
              activitiesState?.activities?.map((activity, idx) => (
                <DataTableItem key={`activity-row-${idx}`}>
                  <DataTableRow className={'p-4'} style={{ verticalAlign: 'top', width: '20ch' }}>
                    <div className="">
                      <span className="fw-semibold text-secondary">{activity?.type || '-'}</span>
                      <div className="">
                        <Badge color={getActivityBadgeStyles(activity?.status)} className="mx-auto my-1">
                          {activity?.status}
                        </Badge>
                      </div>
                      <div className="d-flex flex-column">
                        <span className="fw-semibold text-secondary">Due on:</span>
                        <span>{format(new Date(activity?.date), 'dd/MM/yyyy hh:mm a')}</span>
                      </div>
                      {activity?.completed_date ? (
                        <div className="d-flex flex-column mt-2">
                          <span className="fw-semibold text-secondary">Completed at:</span>
                          <span>{format(new Date(activity?.completed_date), 'dd/MM/yyyy hh:mm a')}</span>
                        </div>
                      ) : null}
                      {activity?.property_list && (
                        <div className="d-flex flex-column mt-2">
                          <span className="fw-semibold text-secondary">Property:</span>
                          <Link to={`/property-list/${activity?.property_list?.id ?? activity?.property_list?._id}`} target='_blank'>
                            <PropertyListHoverCard listing={activity?.property_list} />
                            <Icon name="external" className={'ms-1'} />
                          </Link>
                        </div>
                      )}


                    </div>
                  </DataTableRow>

                  <DataTableRow className={'p-4 d-none d-xxl-table-cell'} style={{ verticalAlign: 'top', width: '30ch' }}>
                    <div className="">
                      <div className='d-flex flex-column'>
                        <span className="fw-semibold text-secondary">
                          Created By
                        </span>

                        {activity?.createdBy ? (
                          <div className="d-flex align-items-center">
                            {activity?.createdBy?.photo ? (
                              <UserAvatar image={getPublicDocumentUrl(activity?.createdBy?.photo)} className="sm me-2"></UserAvatar>
                            ) : (
                              <div >
                                <UserAvatar className="sm me-2" text={findUpper(`${activity?.createdBy?.firstName} ${activity?.createdBy?.lastName}`)}></UserAvatar>

                              </div>
                            )}


                            <div className="d-flex flex-column">
                              <span>
                                {`${activity?.createdBy?.firstName} ${activity?.createdBy?.lastName ?? ''}`}
                              </span>
                              <span>
                                {activity?.createdBy?.phone}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className='d-flex align-items-center'>
                            <div >
                              <UserAvatar className="sm me-2" text={"S"}></UserAvatar>
                            </div>

                            <span>System Info</span>
                          </div>

                        )}
                      </div>
                      <div className='d-flex flex-column mt-2'>
                        <span className="fw-semibold text-secondary">
                          Completed By
                        </span>

                        {activity?.completedBy ? (
                          <div className="d-flex align-items-center">

                            {activity?.completedBy?.photo ? (

                              <UserAvatar image={getPublicDocumentUrl(activity?.completedBy?.photo)} className="sm me-2"></UserAvatar>) : (
                              <div >
                                <UserAvatar className="sm me-2" text={findUpper(`${activity?.completedBy?.firstName} ${activity?.completedBy?.lastName}`)}></UserAvatar>

                              </div>
                            )}


                            <div className="d-flex flex-column">
                              <span>
                                {`${activity?.completedBy?.firstName} ${activity?.completedBy?.lastName ?? ''}`}
                              </span>
                              <span>
                                {activity?.completedBy?.phone}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className='d-flex align-items-center'>

                            {activity?.status === ActivityStatus.Complete || activity?.status === ActivityStatus.AutoCompleted ? (
                              <div className='d-flex align-items-center'>
                                <div className='pe-2'>
                                  <UserAvatar className="sm" text="S" />
                                </div>
                                <span>No one</span>
                              </div>
                            ) : (
                              <span className='text-danger'>{activity?.status}</span>
                            )}
                          </div>
                        )}
                      </div>
                    </div>

                  </DataTableRow>

                  {/* Description & Notes */}
                  {
                    <DataTableRow DataTableRow className={'p-4 d-none d-md-table-cell'} style={{ verticalAlign: 'top' }}>
                      <div
                        style={{
                          maxHeight: '19rem',
                          overflowY: 'auto',
                          scrollbarWidth: 'thin',
                          scrollbarColor: '#edf0f5 transparent'
                        }}
                      >
                        {!shouldShowSystemInfo(activity) && (<div className="mb-2">
                          <span className="fw-semibold text-secondary">Agent's Notes:</span>
                          <div className="mt-1" style={{ wordBreak: 'break-word' }}>
                            {activity?.notes || '-'}
                          </div>
                        </div>)}
                        {shouldShowSystemInfo(activity) && (<div>
                          <span className="fw-semibold text-secondary">System Info:</span>
                          <div className="mt-1" style={{ wordBreak: 'break-word' }}>
                            {activity.type === ActivityTypes.GetMatchedAssistance ? (
                              <pre style={{ whiteSpace: 'pre-wrap' }}>
                                {renderWithLinks(activity?.description || '-')}
                              </pre>
                            ) : (
                              <>{renderWithLinks(activity?.description || '-')}</>
                            )}
                          </div>
                        </div>)}

                      </div>

                    </DataTableRow>
                  }
                  <DataTableRow className="nk-tb-col-tools">
                    {true && (
                      <ul className="nk-tb-actions gx-1" style={{ fontSize: '.9rem' }}>
                        {activity?.status === 'Pending' || activity?.status === 'Overdue' ? (
                          <>
                            <li
                              onClick={() => {
                                toggleCompleteModal();
                                setActivityToComplete(activity);
                              }}
                            >
                              <TooltipComponent
                                icon={'check-c'}
                                direction={'top'}
                                text={'Complete Task'}
                                iconClass={' text-success cursor-pointer'}
                                id={'complete-task'}
                                containerClassName={'cursor-pointer'}
                              />
                            </li>
                            <li
                              className=""
                              onClick={() => {
                                toggleEditModal();
                                setActivityToEdit((_) => activity);
                              }}
                            >
                              <Icon name="edit"></Icon>
                            </li>
                          </>
                        ) : null}
                        <li
                          className=""
                          onClick={() => {
                            toggleViewModal();
                            setActivityToView((_) => activity);



                          }}
                        >
                          <Icon name="eye"></Icon>
                        </li>
                      </ul>
                    )}
                  </DataTableRow>
                </DataTableItem>
              ))
            )}
          </DataTableBody>
          <PreviewAltCard>
            {activitiesState?.error ? (
              <p className="text-center text-danger fs-6">
                Failed to fetch activities.
                <br />
                {activitiesState?.error?.response?.data?.message ?? null}
              </p>
            ) : null}
            {activitiesState?.activities?.length ? (
              <PaginationComponent
                itemPerPage={queryParams?.pageSize}
                totalItems={activitiesState?.totalActivities}
                paginate={(page) => setQueryParams((prev) => ({ ...prev, page }))}
                currentPage={queryParams?.page}
              />
            ) : !activitiesState?.isLoading ? (
              <p className="text-center fw-bold fs-6">
                No Activities found.
                <br />
              </p>
            ) : null}
          </PreviewAltCard>
        </DataTable>
      </Block >
      <ActivityProvider>
        <Modal
          size="lg"
          isOpen={adminAddTaskModal}
          toggle={toggleAdminAddTaskModal}
          onClick={(e) => e.stopPropagation()}
        >
          <ModalBody>
            <span
              onClick={(ev) => {
                ev.preventDefault();
                toggleAdminAddTaskModal();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </span>
            <ActivityCreationForm
              user={user}
              toggleFn={toggleAdminAddTaskModal}
              mutations={{ createActivityFn: activityCreate }}
              showTitle
            />
          </ModalBody>
        </Modal>
        {isViewModalOpen && (
          <Suspense fallback={<LoaderModal />}>
            <Modal isOpen={isViewModalOpen} toggle={toggleViewModal} size="lg" onClick={(e) => e.stopPropagation()}>
              <ModalHeader>
                <h5>Activity Details</h5>
              </ModalHeader>
              <ModalBody>
                <div className="d-flex flex-column" style={{ gap: '1rem' }}>
                  <div>
                    <h6>Agent's Notes</h6>
                    <p
                      style={{
                        maxWidth: screenWidth < ScreenBreakPoints.md ? '10ch' : '75ch',
                        textWrap: 'wrap'
                      }}
                    >
                      {activityToView?.notes || <span className="text-muted">-</span>}
                    </p>
                  </div>
                  <div>
                    <h6>System Info</h6>
                    <p
                      style={{
                        maxWidth: screenWidth < ScreenBreakPoints.md ? '10ch' : '75ch',
                        textWrap: 'wrap'
                      }}
                    >
                      {activityToView.type === ActivityTypes.GetMatchedAssistance ? (
                        <pre>
                          {activityToView?.description ? (
                            renderWithLinks(activityToView?.description)
                          ) : (
                            <span className="text-muted">-</span>
                          )}
                        </pre>
                      ) : (
                        <>
                          {activityToView?.description ? (
                            renderWithLinks(activityToView?.description)
                          ) : (
                            <span className="text-muted">-</span>
                          )}
                        </>
                      )}
                    </p>
                  </div>
                  <div>
                    <h6>Property</h6>
                    {activityToView.property_list && (
                      <p
                        style={{
                          maxWidth: screenWidth < ScreenBreakPoints.md ? '10ch' : '75ch',
                          textWrap: 'wrap'
                        }}
                      >
                        <strong>Reference:</strong> {activityToView.property_list.referNo} <br />
                        <strong>Title:</strong> {activityToView.property_list.propertyTitle} <br />
                        <strong>Type:</strong> {activityToView.property_list.listingType} <br />
                        <strong>Building Name:</strong> {activityToView.property_list.buildingName} <br />
                        <strong>Location:</strong> {activityToView.property_list.communityName} <br />
                        <strong>Emirate:</strong> {activityToView.property_list.emirate} <br />
                      </p>
                    )}
                    {!activityToView.property_list && <span className="text-muted">No info available</span>}
                  </div>
                  <Row>
                    <Col>
                      <h6>Scheduled Date</h6>
                      <p>{format(new Date(activityToView?.date), 'dd/MM/yyyy hh:mm a')}</p>
                    </Col>
                    <Col>
                      <h6>Completed Date</h6>
                      <p>
                        {activityToView?.completed_date && activityToView?.completed_date ? (
                          format(new Date(activityToView?.completed_date), 'dd/MM/yyyy hh:mm a')
                        ) : (
                          <span className="text-muted">Not completed yet</span>
                        )}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h6>Created By</h6>
                      <div className='d-flex align-items-center'>
                        {activityToView?.createdBy?.photo ? (
                          <UserAvatar image={getPublicDocumentUrl(activityToView?.createdBy?.photo)} className="xs me-2"></UserAvatar>
                        ) : (
                          <UserAvatar className="sm me-2" text={findUpper(`${activityToView?.createdBy?.firstName} ${activityToView?.createdBy?.lastName}`)}></UserAvatar>
                        )}
                        <div className='d-flex  flex-column '>
                          <span>
                            {activityToView?.createdBy
                              ? `${activityToView?.createdBy?.firstName} ${activityToView?.createdBy?.lastName ?? ''}`
                              : 'System-generated'}
                          </span>
                          <span>{activityToView?.createdBy?.phone}</span>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <h6>Completed By</h6>
                      {activityToView?.completedBy ? (
                        <div className='d-flex align-items-center'>
                          {activityToView?.completedBy?.photo ? (
                            <UserAvatar image={getPublicDocumentUrl(activityToView?.completedBy?.photo)} className="sm me-2"></UserAvatar>
                          ) : (
                            <UserAvatar className="sm me-2" text={findUpper(`${activityToView?.completedBy?.firstName} ${activityToView?.completedBy?.lastName}`)}></UserAvatar>

                          )}
                          <div className='d-flex  flex-column '>
                            <span>
                              {activityToView?.completedBy
                                ? `${activityToView?.completedBy?.firstName} ${activityToView?.completedBy?.lastName ?? ''}`
                                : 'No one'}
                            </span>
                            <span>{activityToView?.completedBy?.phone}</span>
                          </div>

                        </div>) : (

                        activityToView?.status === ActivityStatus.Complete || activityToView?.status === ActivityStatus.AutoCompleted ? (
                          <div className='d-flex align-items-center'>
                            <div className='pe-2'>
                              <UserAvatar className="sm" text="S" />
                            </div>
                            <span>No one</span>
                          </div>
                        ) : (
                          <span className='text-danger'>{activityToView?.status}</span>
                        )
                      )}
                    </Col>
                  </Row>
                </div>
                <div className="d-flex justify-content-end mt-3">
                  <Button onClick={toggleViewModal}>OK</Button>
                </div>
              </ModalBody>
            </Modal>
          </Suspense>
        )}
        {isCompleteModalOpen && (
          <Suspense fallback={<LoaderModal />}>
            <ActivityCompletionModal
              isOpen={isCompleteModalOpen}
              toggleModal={toggleCompleteModal}
              user={user}
              completedActivity={activityToComplete}
            />
          </Suspense>
        )}
        {isEditModalOpen && (
          <Suspense fallback={<LoaderModal />}>
            <ActivityEditModal isOpen={isEditModalOpen} toggleFn={toggleEditModal} completedActivity={activityToEdit} />
          </Suspense>
        )}
      </ActivityProvider>
    </React.Fragment >
  );
};

export default ActivitiesTableView;
