import { Link } from "react-router-dom";
import { Icon } from "../../../../../components/Component";

/**
 * A component for rendering the card with links to navigate the user profile.
 *
 * @param {Object} props - The props for the component.
 * @param {boolean} props.isAgent - Whether the user is an agent or not
 * @param {React.Dispatch<React.SetStateAction<ViewportInfo>>} props.setViewportInfo - function to update the viewport information
 * @returns {JSX.Element} - The rendered component.
 */

const NavigationCard = ({ isAgent, setViewportInfo }) => {
  return (
    <ul className="link-list-menu">
      {!isAgent ? (
        <li
          onClick={(e) => {
            setViewportInfo((prev) => ({ ...prev, isSm: false }));
            handleAnchorClick(e, "activities");
          }}
        >
          <Link>
            <Icon name="activity"></Icon>
            <span>Activities</span>
          </Link>
        </li>
      ) : null}
       <li
          onClick={(e) => {
            setViewportInfo((prev) => ({ ...prev, isSm: false }));
            handleAnchorClick(e, "lead_source_log");
          }}
        >
          <Link>
            <Icon name="link-group"></Icon>
            <span>Lead Source History</span>
          </Link>
        </li>
      {!isAgent ? (
        <li
          onClick={(e) => {
            setViewportInfo((prev) => ({ ...prev, isSm: false }));
            handleAnchorClick(e, "propertyCards");
          }}
        >
          <Link>
            <Icon name="cards"></Icon>
            <span>Property Cards</span>
          </Link>
        </li>
      ) : null}
      <li
        onClick={(e) => {
          setViewportInfo((prev) => ({ ...prev, isSm: false }));
          handleAnchorClick(e, "deals");
        }}
      >
        <Link>
          <Icon name="file-docs"></Icon>
          <span>Deals</span>
        </Link>
      </li>
      <li
        onClick={(e) => {
          setViewportInfo((prev) => ({ ...prev, isSm: false }));
          handleAnchorClick(e, "personalInfo");
        }}
      >
        <Link>
          <Icon name="user-fill-c"></Icon>
          <span>Personal Information</span>
        </Link>
      </li>
      {isAgent ? (
        <li
          onClick={(e) => {
            setViewportInfo((prev) => ({ ...prev, isSm: false }));
            handleAnchorClick(e, "teams");
          }}
        >
          <Link>
            <Icon name="users"></Icon>
            <span>Teams</span>
          </Link>
        </li>
      ) : null}
    </ul>
  );
};

function handleAnchorClick(event, sectionId) {
  event.preventDefault();

  const fixedTopBarHeight = 100;

  const contentSection = document.getElementById(sectionId);
  if (contentSection) {
    const top =
      contentSection.getBoundingClientRect().top +
      window.pageYOffset -
      fixedTopBarHeight;
    window.scrollTo({
      top: top,
      behavior: "smooth",
    });
  }
}

export default NavigationCard;
